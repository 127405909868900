// 
// _footer.scss
//
.footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    color: $footer-color;
    text-align: center;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    height: $footer-height;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.05);
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);
}
  
@media (max-width: 992px) {
    .footer {
        left: 0;
    }
}

// Enlarge menu
.vertical-collapsed {
    .footer {
        left: $sidebar-collapsed-width;
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}