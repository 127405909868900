// 
// _header.scss
// 
#page-top-bar,
#page-top-menu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    background: radial-gradient(circle, #ffffffb8 60%, #ffffff80 85%);
    box-shadow: $box-shadow;
}

.navbar-header {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: $header-height;
    padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;
}

.navbar-brand-box {
    padding: 0;
    text-align: center;
    width: $navbar-brand-box-width;
}

.logo {
    line-height: 70px;

    .logo-sm {
        display: none;
    }
}

.logo-light {
    display: none;
}

// dropdown-topbar
.dropdown-topbar {
    .btn-light {
       background-color: $dropdown-topbar !important;
       border: $dropdown-topbar !important;

       &:focus {
           box-shadow: 0 0 0 0.15rem rgba(216,217,219,.5) !important;
       }
    }
}

/* Search */
.app-search {
    padding: calc(#{$header-height - 38px} / 2) 0;

    .form-control {
        border: none;
        height: 38px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: $topbar-search-bg;
        box-shadow: none;
        border-radius: 30px;
    }
    span {
        position: absolute;
        z-index: 10;
        font-size: 13px;
        line-height: 38px;
        right: 16px;
        top: 0;
        color: $gray-700;
    }
}

// Mega menu
.megamenu-list {
    li {
        position: relative;
        padding: 5px 0;
        a {
            color: $dropdown-color;
        }
    }
}

@media (max-width: 992px) {
    .navbar-brand-box {
        width: auto;
    }

    .logo {
        span.logo-lg {
            display: none;
        }

        span.logo-sm {
            display: inline-block;
        }
    }
}

.page-content {
    color: $page-content-color;
    padding: calc(#{$header-height}) 0 $footer-height 0;
}

body[data-sidebar] {
    .page-content {
        position: relative;
        min-height: calc(100vh - 4.375rem);
        padding: calc(#{$header-height}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);

        .container-fluid {
            position: relative;

            .page-title-box {
                .breadcrumb-item {
                    &.active {
                        color: $white;
                    }
                }
            }
        }
    }
}

.header-item {
    height: $header-height;
    box-shadow: none !important;
    color: $header-item-color;
    border: 0;
    border-radius: 0;

    &:hover {
        color: $header-item-color;
    }
}

.header-profile-user {
    height: 36px;
    width: 36px;
    background-color: $gray-300;
}

.noti-icon {
    i {
        font-size: 22px;
        color: $header-item-color;
    }

    .badge {
        position: absolute;
        top: 15px;
        right: 4.5px;
    }
}

.notification-item {
    .media {
        padding: 0.75rem 1rem;

        &:hover {
            background-color: $gray-100;
        }
    }
}

// Dropdown with Icons
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    border: 1px solid transparent;
    color: $gray-600;

    img {
        height: 24px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        border-color: $gray-200;
    }
}

// Full Screen
.fullscreen-enable {
    [data-toggle="fullscreen"] {
        .bx-fullscreen::before {
            content: "\ea3f";
        }
    }
}

body[data-topbar="dark"] {
    #page-top-bar,
    #page-top-menu {
        background-color: $header-dark-bg;
    }

    .navbar-header {
        .dropdown.show {
            .header-item {
                background-color: rgba($white, 0.05);
            }
        }

        .waves-effect .waves-ripple {
            background: rgba($white, 0.4);
        }
    }

    .header-item {
        color: $header-dark-item-color;
    
        &:hover {
            color: $header-dark-item-color;
        }
    }

    .header-profile-user {
        background-color: rgba($white, 0.25);
    }
    
    .noti-icon {
        i {
            color: $header-dark-item-color;
        }
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .app-search {
        .form-control {
            background-color: rgba($topbar-search-bg,0.07);
            color: $white;
        }
        span,
        input.form-control::-webkit-input-placeholder {
            color: rgba($white,0.5);
        }
    }
}

body[data-sidebar="dark"] {
    .navbar-brand-box {
        background: $sidebar-dark-bg;
    }

    @media (max-width: 575.98px) {
        .navbar-brand-box {
            padding: 0 1rem 0 3rem;
        }
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }
}

@media (max-width: 575.98px) {
    .navbar-header {
        .dropdown {
            position: static;

            .dropdown-menu {
                left: 10px !important;
                right: 10px !important;
            }
        }
    }

    .top-navigation {
        position: absolute;
        left: 0;
        right: 0;
        background-color: $header-dark-bg;
    }
}

@media (max-width: 380px) {
    .navbar-brand-box {
        display: none !important;
    }
}

body[data-layout="horizontal"] {
    .navbar-header {
        padding: 0;
    }
    .navbar-brand-box {
        width: auto;
        display: inline-block;
    }
    .page-content {
        margin-top: $header-height;
        padding: calc(180px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);
    }    
}

@media (max-width: 992px) { 
    body[data-layout="horizontal"] {
        .page-content {
            padding: calc(98px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);
        }    
    }
}

@media (min-width: 992px) { 
    body[data-layout="horizontal"] {
        .navbar-header {
            padding: 0 calc(#{$grid-gutter-width} / 2);

            .navbar-collapse {
                justify-content: flex-end;
            }
        }

        .navbar-brand-box {
           padding-left: 0;
        }    
    }
}

