// 
// _pagination.scss
// 

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
  }
}

.active > .page-link, .page-link.active {
  background-color: #5b626b;
}