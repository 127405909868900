// 
// authentication.scss
//
// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

.ex-page-content {
  h1 {
    font-size: 98px;
    font-weight: 500;
    line-height: 150px;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
  }
}