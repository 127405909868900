.text-shadow {
  text-shadow: 0 0 6px #000;
}

.url-input {
  .form-control {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}

.info-item {
  display: flex;
  align-items: center;
  flex-direction: column;

  .info-image {
    border-radius: 100%;
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);
    background: radial-gradient(circle, hsla(0, 0%, 100%, .722) 60%, hsla(0, 0%, 100%, .502) 85%);
    box-shadow: 0 -3px 31px 0 #0000000d, 0 6px 20px 0 #00000005;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;

    img {
      width: 3rem;
      height: 3rem;
    }
  }
}