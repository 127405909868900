//
// Google font - Roboto
//

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600');

.h1, h1 {
  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }

  &.header-xl {
    color: #ffffff;
    text-shadow: 0px 0px 6px #000;
    font-size: 3rem;

    @include media-breakpoint-up(md) {
      font-size: 4rem;
    }
  }
}
.h2, h2 {
  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }

  &.header-xl {
    font-size: 2.5rem;

    @include media-breakpoint-up(md) {
      font-size: 3rem;
    }
  }
}
.h3, h3 {
  @include media-breakpoint-up(md) {
    font-size: 1.9375rem;
  }

  &.header-xl {
    font-size: 1.9375rem;

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }
  }
}
.h4, h4 {
  @include media-breakpoint-up(md) {
    font-size: 1.5625rem;
  }

  &.header-xl {
    font-size: 1.5625rem;

    @include media-breakpoint-up(md) {
      font-size: 1.9375rem;
    }
  }
}
.h5, h5 {
  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }

  &.header-xl {
    font-size: 1.25rem;

    @include media-breakpoint-up(md) {
      font-size: 1.5625rem;
    }
  }
}
.h6, h6 {
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  &.header-xl {
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }
}