.gradient-color {
  position: relative;
  display: flex;
  align-items: center;
  gap: 3rem;
  margin: 1.5rem 0 1.5rem 0.5rem;
  padding: 0 0 0 6rem;

  .gradient-bar-points {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3rem;
    margin: 0 1.5rem;
    height: 100%;

    .gradient-bar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #5b626b;
    }

    .gradient-points {
      position: relative;
      width: 100%;
      height: 100%;

      .start-point,
      .end-point {
        position: absolute;
        cursor: grab;
        left: -0.25rem;
        right: -0.25rem;
        height: 1rem;
        border-radius: 0.25rem;
        border: 1px solid #5b626b;
        transform: translateY(-50%);
      }
    }
  }

  .color-pickers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
}