// 
// _buttons.scss
// 
button, a {
  outline: none !important;

  &:focus, &:hover {
    background: transparent;
  }
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark {
  color: $gray-200 !important;
}

.btn-primary {
  background: radial-gradient(circle, #ffffffb8 60%, #ffffff80 85%) !important;
  border: unset !important;

  body[data-sidebar] & {
    background: #5b626b !important;
    color: $white;
  }
}