.shorten-links {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
    background: radial-gradient(circle, #ffffffa6 10%, #ffffffe8 50%);
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);
    border-radius: 8px 8px 8px 8px;

    .shorten-link {
        display: flex;
        color: #5b626b;
        padding: 16px 20px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        font-size: 18px;

        @include media-breakpoint-down(md) {
            display: flex;
        }

        span {
            display: inline-block;
            vertical-align: middle;
        }

        .short-link {
            color: #5b626b;
            padding-right: 25px;
        }
    }
}