// 
// _layouts.scss
//
body[data-layout-size="boxed"] {
    background-color: $boxed-body-bg;

    #layout-wrapper {
        background-color: $body-bg;
        box-shadow: $box-shadow;
        max-width: $boxed-layout-width;
        margin: 0 auto;
    }

    #page-top-bar,
    #page-top-menu {
        max-width: $boxed-layout-width;
        margin: 0 auto;
    }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    }

    &.vertical-collapsed {
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }
    }
}

// Horizontal Boxed Layout
body[data-layout="horizontal"][data-layout-size="boxed"] {
    .container-fluid, .navbar-header {
        max-width: $boxed-layout-width;
    }
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding: 0;
}

#layout-wrapper {
    position: relative;
    min-height: 100vh;

    .layout-bg-image {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../images/account-background.jpg');
        background-size: cover;
        background-attachment: fixed;
        z-index: 0;

        .layout-url-view & {
            background-attachment: unset;
            background-image: unset;
            background-size: unset;
        }
    }

    .layout-bg-color {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: radial-gradient(circle, hsl(0deg 0% 100% / 29%) 60%, hsl(0deg 0% 100% / 8%) 85%);
        -webkit-backdrop-filter: blur(1rem);
        backdrop-filter: blur(1rem);
    }
}