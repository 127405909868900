//
// Forms.scss
//
.form-control {
  background: radial-gradient(circle, #ffffffb8 60%, #ffffff80 85%);

  &[readonly] {
    background: #e9ecef;
  }
}

// checkbox input right
.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: 1.25rem;

  .form-check-input {
    right: 0;
    margin-left: 0;
  }

  .form-check-label {
    display: block;
  }
}

.custom-control-right {
  padding-left: 0;
  padding-right: 1.5rem;
  display: inline-block;

  .custom-control-label {
    display: inline-block;

    &:before, &:after {
      left: auto;
      right: -1.5rem;
    }
  }

  .custom-control-input {
    left: auto;
  }
}

.input-group-text {
  .form-control {
    background: transparent;
    border: unset;
    padding: 0;
  }

  select {
    &.form-control {
      position: relative;

      &:before {
        position: absolute;
        content: '';
      }
    }
  }

  img {
    height: 0.8125rem;
    margin-right: 0.25rem;
  }
}

/* Select Input */
.select-input {
  position: relative;

  .wrapper {
    display: inline-flex;
    border-radius: .25rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
  }

  .icon-text {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    img {
      height: .8125rem;
    }
  }

  .options {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, 100%);
    min-width: 100%;
    background: #e9ecef;
    border-radius: 0.25rem;
    padding: 0.25rem 0;
    z-index: 1;

    .input-value {
      color: #5b626b;
      cursor: pointer;
      padding: 0.25rem 0.5rem;
      text-align: left;

      &.active {
        background: #5b626b;
        color: #e9ecef;
      }
    }
  }
}

.input-group-text {
  &:has(.select-input) {
    position: relative;

    .select-input {
      position: unset;

      .wrapper {
        border-radius: unset;
        padding: 0;
        border: unset;
      }
    }
  }
}