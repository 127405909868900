// 
// _card.scss
// 
body[data-sidebar] {
  .card {
    --bs-card-border-radius: 1rem;

    margin-bottom: $grid-gutter-width;
    box-shadow: $box-shadow;

    color: #5b626b;
    background: radial-gradient(circle, #ffffffa6 10%, #ffffffe8 50%);
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);

    .card-body {
      .table > :not(caption) > * {
        border-color: #5b626b;

        > * {
          color: #5b626b;
          border-color: #5b626b;
          background: unset;
          box-shadow: none;
          backdrop-filter: none;
        }
      }

      .text-white {
        color: #5b626b !important;
      }

      .card {
        background: unset;
        box-shadow: none;
        backdrop-filter: none;

        .card-body {
          background: unset;
          box-shadow: none;
          backdrop-filter: none;
        }
      }

      rect {
        fill: transparent;
      }
    }
  }

  table {
    border-color: rgba(255, 255, 255, 0.71875);
  }
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}

