// 
// url.scss
//

/* Url */
.url-container {
  background: #fff;
  background-size: cover;
  min-height: calc(100vh - #{$header-height} - 2em - #{$footer-height});
  margin-left: auto;
  margin-right: auto;

  .url-bg-container {
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background-color: #000;
    -o-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
  }

  .url-bg-img {
    position: absolute;
    -webkit-filter: blur(34px);
    -moz-filter: blur(34px);
    -o-filter: blur(34px);
    -ms-filter: blur(34px);
    filter: blur(34px);
    height: 100%;
    width: 100%;
  }

  .url-content {
    position: relative;
    padding:0.5em;
    z-index: 555;
    width: 100%;
    margin: 1em auto 1em auto;
    max-width: 400px;

    .url-artwork {
      border-radius: 10px;
      max-width: 100%;
      height: auto;
    }

    .url-title {
      background: #00000061; border-radius: 10px;
      color: #fff;
      font-family: Segoe UI, Helvetica, Arial, sans-serif; font-size: 2.5em;
      font-weight: 700;
      letter-spacing: 1px;
      margin: 15px 0 20px 25%; padding: .5em;
      text-align: center;
      width: 50%;
    }

    .url-list-container {
      width: 100%;
    }

    .url-item-container {
      background: #00000061;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: none;
      margin: 5px;
      overflow: hidden;
      width: 100%;
      backdrop-filter: blur(1em);
      padding: 10px;
    }
  }

  .url-item-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding: .7em 1em;
    width: 100%;

    &:last-child {
      border-bottom: none;
    }

    &:not(.url-item-container-custom-text):hover {
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
    }

    &.url-item-container-custom-text {
      background: #f8f8f8;
    }

    .url-item-text {
      text-align: center;
      font-weight: 500;
      width: 100%;
      font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    }

    .url-item-logo {
      float: left;
      max-height: 4em;
      max-width: 7em;
    }

    .url-item-custom-platform {
      float: left;
      font-size: 1.1em;
      width: 100%;
      max-width: 12em;
      text-align: left;
      font-family: Arial;
      font-weight: 600;
      word-break: break-all;
    }

    .url-item-button {
      float: right;
      padding: 0.5em 1em;
      background: #fff;
      color: rgb(97 97 97);
      border: 1px solid rgb(218 218 218);
      font-weight: bold;
      border-radius: 25px;
      cursor: pointer;

      a {
        text-decoration: none;
        color: rgb(97 97 97);

        &:hover,
        & a:hover {
          background: rgb(60, 60, 60);
          border: 1px solid transparent;
          color: #fff;
        }
      }
    }

    .url-item-text {
      margin-bottom: .5rem;
      font-size: 1.5em;
      font-weight: 1000;
    }
  }
}

@media only screen and (max-width: 1170px) {
}
  