// 
// _nav.scss
// 
.nav-tabs,.nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $font-weight-medium;
    }
  }
}

.nav-tabs {
  .nav-link {
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #5b626baa;
    border-color: #5b626baa;
    color: white;

    &.active {
      background: #5b626b;
      border-color: #5b626b;
      color: white;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $font-weight-medium;
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;

    .nav-link {
      border: none;

      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $white;

        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

.nav-tabs-custom-list {
  flex-direction: column !important;
  position: relative;
  color: $dark;
  border-bottom: none;

  .nav-link {
    margin-bottom: 3px;
    border-bottom: 1px solid $gray-300;

    &.active {
      border: none;
      border-bottom: 1px solid $primary;
      color: $white;

      &:after {
        transform: scale(1);
      }
    }
  }
}

.navbar-header {
  .navbar-collapse {
    @include media-breakpoint-down(md) {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      background-color: $header-bg;
      border-top: $nav-tabs-border-width solid $nav-tabs-border-color;
    }

    .navbar-nav {
      > .nav-item {
        padding: 5px 0 5px 20px;
        position: relative;

        @include media-breakpoint-down(md) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }

      .nav-item {
        .nav-link {
          padding: $nav-link-padding-x $nav-link-padding-y;
          color: $navbar-light-color;

          &:hover, &:focus {
            color: $primary;
          }
          &.active {
            color: $primary;
          }
        }

        .dropdown-toggle {
          &:after {
            content: "\f078";
            font-family: 'Font Awesome 5 Free';
            font-size: 0.5rem;
            margin-left: 0.35rem;
            font-weight: 900;
            vertical-align: middle;

            @include media-breakpoint-down(md) {
              position: absolute;
              right: 25px;
            }
          }
        }

        .dropdown-menu {
          box-shadow: none;
        }
      }
    }
  }

  .navbar-toggler {
    color: $black;
  }
}

.tab-content {
  &.tab-group {
    .tab-pane {
      display: none;
    }

    .active {
      display: block;
    }
  }
}