.color-picker {
  display: flex;
  align-items: center;
  gap: 3rem;

  .color-picker-color {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    border: 1px solid #5b626b;
  }
}

.color-transparent-picker {
  display: flex;
  align-items: center;
  gap: 3rem;

  .picker-color {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    border: 1px solid #5b626b;
  }

  .picker-wrapper {
    display: flex;
    align-items: center;

    .left-picker {

    }

    .right-picker {
      .IroSlider {
        &:not(:last-child) {
          display: none !important;
        }
      }
    }
  }
}