// 
// _table.scss
// 
.table {
  th {
    font-weight: $font-weight-bold;
  }
}

//Table centered
.table-centered {
  td,th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

.table-list {
  tr {
    td, th {
      &:nth-child(2) {
        text-align: right;
      }

      &:nth-child(3) {
        > div {
          display: flex !important;

          div {
            &:nth-child(1) {
              height: 20px;
              background-color: #1967d2;
              margin-right: 5px;
            }

            &:nth-child(2) {
              width: 60px;
            }
          }
        }
      }
    }
  }
}

.pagination {
  .page-item {
    &:not(.disabled) {
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}