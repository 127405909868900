.dataTables_paginate {
    .pagination  {
        float: right;
    }
}

.dataTables_filter {
    label {
        float: right;
    }
}

.rangeslider__fill {
    background-color: #007BFF !important;
}

.mhp-50 {
    max-height: 50px !important;
}

.mhp-100 {
    max-height: 100px !important;
}

.mhp-150 {
    max-height: 150px !important;
}

.mhp-200 {
    max-height: 200px !important;
}

.mhp-250 {
    max-height: 250px !important;
}

.mhp-300 {
    max-height: 300px !important;
}

.mwp-50 {
    max-width: 50px !important;
}

.mwp-100 {
    max-width: 100px !important;
}

.mwp-150 {
    max-width: 150px !important;
}

.mwp-200 {
    max-width: 200px !important;
}

.mwp-250 {
    max-width: 250px !important;
}

.mwp-300 {
    max-width: 300px !important;
}